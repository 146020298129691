<!-- Footer -->
<template>
  <footer class="section7">
    <div class="footer">
      <div class="info">
        <a href="https://beian.miit.gov.cn/#/" target="_blank">
          <p>{{ portalSet?.copyright }}</p>
        </a>
        <div>
          <p>联系电话：{{ portalSet?.phone }}</p>
        </div>
      </div>
      <!-- <div class="fo_top">
        <ul class="fo_nav">
          <li v-for="(item, index) in section" :key="index">
            <h4
              :class="{
                on: isMobile && activeIndex === index,
              }"
              @click="handleTitleClick(index)"
            >
              {{ item.title }}
            </h4>
            <p
              :style="{
                display:
                  !isMobile || (isMobile && activeIndex === index)
                    ? 'block'
                    : 'none',
              }"
              v-for="(subItem, subIndex) in item.list"
              :key="subIndex"
            >
              <span>
                <a @click="$router.push(subItem.path)">
                  {{ subItem.title }}
                </a>
              </span>
            </p>
          </li>
        </ul>
        <div class="fo_code">
          <img src="../images/20211011055058916.png" alt="" />
          <p>关注官方微信公众号<br />方圆十一</p>
        </div>
      </div>

      <div class="fo_co">
        <div class="fo_logo">
          <a><img src="images/logo_fo.png" alt="" /></a>
        </div>
        <a href="https://beian.miit.gov.cn/#/" target="_blank">
          <p>{{portalSet.copyright}}</p>
        </a>
        <div>
          <p>{{portalSet.phone}}</p>
        </div>
      </div> -->
    </div>
  </footer>
</template>

<script>
import portalApi from '../../../../lvya-common/src/api/eos/portal'
import { debounce } from '../../../../lvya-common/src/utils/DebounceThrottle.js'
import { mapState } from 'vuex'

export default {
  props: {},
  components: {},
  data() {
    return {
      portalSet: null,
      portalSchoolInfo: '',
      info: '',
      section: [
        {
          title: '用户角色',
          list: [],
        },
        {
          title: '直通车',
          list: [
            { title: '云平台', path: '', target: '_blank' },
            { title: '校友卡申办', path: '', target: '_blank' },
            { title: 'FAQ', path: '', target: '_blank' },
            { title: '加入我们', path: '', target: '_blank' },
          ],
        },
        {
          title: '联系我们',
          list: [
            { title: '电话：010-88628801', path: '' },
            { title: '邮编：100039', path: '' },
            { title: '本部地址：北京市海淀区玉泉路66号', path: '' },
            { title: '北校区地址：北京市海淀区苏家坨镇环谷园路8号', path: '' },
          ],
        },
      ],
      activeIndex: undefined,
      pageWidth: 1080,
    }
  },
  computed: {
    isShow() {
      return this.$route.path !== '/portalDetails'
    },
    ...mapState({
      roles: (state) => state.yzsyStore.roles,
    }),
    isMobile() {
      return this.pageWidth < 1023
    },
  },
  watch: {
    roles: {
      handler(val) {
        this.section[0].list = val
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 获取门户基础设置
    async getPortalSet() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      let resData = await portalApi.getSet({
        noSchoolId: true,
        schoolId: '7',
      })
      console.log({ resData }, 'portal set')
      this.portalSet = resData
    },
    handleTitleClick(index) {
      if (this.activeIndex === index) {
        this.activeIndex = undefined
      } else {
        this.activeIndex = index
      }
      // if (this.pageWidth < 1023) {

      // } else {

      // }
      console.log(this.pageWidth, 'pageWidth')
    },
    async header() {
      const resData = await portalApi.getSetInfoBySid({
        id: '7',
      })
      this.info = resData
    },
  },
  created() {
    this.portalSchoolInfo = JSON.parse(
      localStorage.getItem('portalSchoolInfo')
    )
  },
  async mounted() {
    this.pageWidth = document.documentElement.clientWidth
    window.addEventListener(
      'resize',
      debounce((e) => {
        this.pageWidth = document.documentElement.clientWidth
      }, 50)
    )
    // this.header()
    this.getPortalSet()
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
  },
}
</script>

<style lang="less" scoped>
@import "../style/common.css";
.footer {
  padding: 0.2rem 0;

  .info {
    max-width: 14.8rem;
    margin: 0 auto;
    // overflow: hidden;
    // border-top: 1px solid #d9d9d9;
    position: relative;
    // height: 1.4rem;
    // padding: 1rem;
    p {
      text-align: center;
      font-size: 0.18rem;
      color: #888;
      margin: 0.2rem 0;
      // line-height: 1.4rem;
    }
  }
}
</style>
