<!-- pageName -->
<template>
  <div class="portalFrame">
    <component v-bind:is="currentFrame"></component>
    <div class="mantle_div" v-if="isClosed">
      <p>暂停访问</p>
    </div>
  </div>
</template>

<script>
// import ErshiFrame from '../ershi/components/ershiFrame.vue'
// import DeviceUtil from '@lvya-common/src/utils/DeviceUtil'
import portalApi from '../../../lvya-common/src/api/eos/portal'
const YzsyMainFrame = () => import(/* webpackChunkName: "YzsyMainFrame", webpackMode: "eager" */ '../yzsy/components/yzsyMainFrame.vue')

export default {
  components: {
    YzsyMainFrame,
  },
  props: {},
  data() {
    return {
      currentFrame: '',
      isClosed: '',
      ishui: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * 配置本地化写死域名或ID逻辑
     * ！！！！共有两处，搜索fnFindDomain
     */
    fnFindDomain(_hostName) {
      let _oDomain = [
        { lvyaDomain: 'xccqjjzx.lvya.org', domain: ['xichangnccq.com', 'www.xichangnccq.com'] },
        { lvyaDomain: 'www.xjlyxx.net', domain: ['172.10.1.8', '172.10.1.30', 'www.xjlyxx.net'] }, // 新疆林业学校本地化部署
      ]
      for (const iterator of _oDomain) {
        if (iterator.domain.includes(_hostName)) {
          return iterator.lvyaDomain
        }
      }
      return _hostName
    },
    // 域名换取学校信息
    async getSchoolInfo() {
      let hostName = window.location.hostname.includes('localhost') ? window.GateWayServer.split('//')[1] : window.location.hostname
      hostName = this.fnFindDomain(hostName)
      console.log('portalFrame:', hostName)
      // let res
      // if (hostName == 'xccqjjzx.lvya.org') {
      //   res = { id: '3476', name: '西昌市农村产权交易中心', }
      // } else if (hostName == 'www.xjlyxx.net') {
      //   res = await portalApi.getSchool({ domain: '172.10.1.8' })
      // } else {
      //   res = await portalApi.getSchool({ domain: hostName })
      // }
      // localStorage.setItem('portalSchoolInfo', JSON.stringify(res))
      // Cookies.set('EOSSchoolId', res.id)
      // console.log(res.id)
      let resData = await portalApi.getSetInfoBySid({ id: '7' })
      localStorage.setItem('pcmodel', JSON.stringify(resData.pcmodel))
      console.log(resData, '2222')
      if (resData.ishui == 'Y') {
        document.body.style.filter = 'grayscale(100%)'
      }
      this.currentFrame = 'YzsyMainFrame'
      // this.currentFrame = 'ErshiFrame'
    },
  },
  // beforeCreate() {
  //   // 目前只支持交易网切换设备
  //   let domain = [
  //     'xichangnccq.com', 'www.xichangnccq.com', 'xccqjjzx.lvya.org', // 西昌市农村产权交易中心
  //   ]
  //   let hostName = window.location.hostname.includes('localhost') ? window.GateWayServer.split('//')[1] : window.location.hostname
  //   if (!DeviceUtil.isWeb() && domain.includes(hostName)) {
  //     this.$router.replace('/portalMobile')
  //   }
  // },
  created() {
    this.getSchoolInfo()
    this.isClosed = JSON.parse(localStorage.getItem('areaSchoolInfo'))?.state == 'N'
  },
  mounted() {},
  beforeDestroy() {
    document.body.style.filter = 'none'
  },
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.portalFrame{
  // filter: grayscale(100%);
  // -webkit-filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -ms-filter: grayscale(100%);
  // -o-filter: grayscale(100%);
  // filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  // -webkit-filter: grayscale(1);
  .mantle_div{
    width: 100%;
    height: 100vh;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    p{
      line-height: 100vh;
      text-align: center;
      color: #fff;
      font-size: 22px;
    }
  }
}
</style>
