/*
 * 节流防抖
 * http://fszer.github.io/2018/01/21/vue%E4%B8%8Ethroltte%E7%9A%84%E5%9D%91/
 */

// 函数防抖
function debounce(fn, wait = 500) {
  let timeout = null
  return function() {
    var args = arguments
    if (timeout !== null) clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, args)
    }, wait)
  }
}

// 函数节流
function throttle(fn, delay = 500) {
  var lastTime
  var timer
  return function() {
    var args = arguments
    // 记录当前函数触发的时间
    var nowTime = Date.now()
    if (lastTime && nowTime - lastTime < delay) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        // 记录上一次函数触发的时间
        lastTime = nowTime
      }, delay)
    } else {
      lastTime = nowTime
      fn.apply(this, args)
    }
  }
}
export {
  debounce,
  throttle
}
